<template>
  <div class="page">
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
      </li>
    </ul>
    <div class="mainBox">
      <div class="tabelDiv">
        <BusinessLicenseInfo v-if="clickIndex == 1"></BusinessLicenseInfo>
        <ProductLicense v-if="clickIndex == 2"></ProductLicense>
        <RegistrationCertificate v-if="clickIndex == 3"></RegistrationCertificate>
        <BusinessApproval v-if="clickIndex == 4"></BusinessApproval>
      </div>
      <div class="saveDiv clearfix">
        <div class="pageBtn finger btnSure fr">下一步</div>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessLicenseInfo from './businessLicenseInfo'
import ProductLicense from './productLicense'
import RegistrationCertificate from './registrationCertificate'
import BusinessApproval from './businessApproval'
export default {
  name: 'manufacturerManage',
  components: {
    BusinessLicenseInfo,
    ProductLicense,
    RegistrationCertificate,
    BusinessApproval,
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach((e, i) => {
        totle = totle * 1 + e.money * 1
      })
      return totle
    },
  },
  data() {
    return {
      isLoad: false,
      cityList: [
        {
          value: 'New York',
          label: 'New York',
        },
        {
          value: 'London',
          label: 'London',
        },
      ],
      navFrist: [
        {
          name: '营业执照信息',
          num: 140,
          type: 1,
        },
        {
          name: '生产许可证',
          num: 40,
          type: 2,
        },
        {
          name: '备案凭证',
          num: 40,
          type: 3,
        },
        {
          name: '首营审批',
          num: 40,
          type: 4,
        },
      ],
      clickIndex: 1,
    }
  },
  methods: {
    changePage(e) {},
    clickNav(type) {
      this.clickIndex = type
    },
  },
}
</script>

<style scoped lang="less">
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .mainBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 16x 20px rgb(0, 0, 0);
    overflow-y: scroll;
  }
  .tabelDiv {
    flex: 1;
    border-radius: 0 8px 8px 8px;
    padding: 20px 0px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
.centeFont {
  margin: 0 10px;
}
.saveDiv {
  padding: 0 23px 29px 0;
}
</style>
