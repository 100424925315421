<template>
  <div class="content">
    <!--  基础信息 -->
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="营业执照"></FromLabel>
        <div class="formFlIpt647 fl">
          <Input class="iviewIptWidth307" placeholder="仅支持jpg.gif.png等格式的图片">
            <span slot="append" class="finger"> 上传 </span>
          </Input>
        </div>
        <FromLabel label="上传合同"></FromLabel>
        <div class="formFlIpt647 fl">
          <Input class="iviewIptWidth307" placeholder="仅支持jpg.gif.png等格式的图片">
            <span slot="append" class="finger"> 上传 </span>
          </Input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="公司名称" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="统一社会信用代码" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307"></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="类型"></FromLabel>
        <div class="formFlIpt647 fl">
          <Select v-model="type" class="iviewIptWidth307">
            <Option value="com">.com</Option>
            <Option value="org">.org</Option>
            <Option value="io">.io</Option>
          </Select>
        </div>
        <FromLabel label="是否为供应商"></FromLabel>
        <div class="formFrIpt fl">
          <Select v-model="type" class="iviewIptWidth307">
            <Option value="com">.com</Option>
            <Option value="org">.org</Option>
            <Option value="io">.io</Option>
          </Select>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="住所"></FromLabel>
        <div class="allWidth">
          <Select v-model="type" class="iviewIptWidth165">
            <Option value="com">.com</Option>
            <Option value="org">.org</Option>
            <Option value="io">.io</Option>
          </Select>
          <Select v-model="type" class="iviewIptWidth165 marginLeft20">
            <Option value="com">.com</Option>
            <Option value="org">.org</Option>
            <Option value="io">.io</Option>
          </Select>
          <Select v-model="type" class="iviewIptWidth307 marginLeft20">
            <Option value="com">.com</Option>
            <Option value="org">.org</Option>
            <Option value="io">.io</Option>
          </Select>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="法人" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="当地进院最低价"></FromLabel>
        <div class="formFlIpt647 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307"></DatePicker>
        </div>
        <FromLabel label="注册资本"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="营业期限"></FromLabel>
        <div class="formFlIpt647 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165"></DatePicker>
          <span class="marginWords10"> 至 </span>
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165"></DatePicker>
          <Checkbox class="singleCheckBox">长期</Checkbox>
        </div>
        <FromLabel label="登记日期"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
          <!-- <span class="afterWords10"> 元</span> -->
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="经营范围" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <Select v-model="type" class="iviewIptWidth1075">
            <Option value="com">.com</Option>
            <Option value="org">.org</Option>
            <Option value="io">.io</Option>
          </Select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
export default {
  name: 'businessLicenseInfo',
  components: {
    FromLabel,
  },
  data() {
    return {
      type: '',
      license: [],
      isLoad: false,
      imgList: [{ src: '' }, { src: '' }],
      typeList: [1],
    }
  },
  created() {},
  methods: {
    changePage(e) {},
  },
}
</script>

<style scoped lang="less">
.content {
  padding: 0px 30px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}
</style>
